<template>
  <div id="user">
    <a-page-header
      title="操作日志"
      style="padding-left: 0; margin-bottom: 10px; border-bottom: 1px solid rgb(235, 237, 240)"
    />
    <a-form layout="inline" style="display: flex; flex-wrap: wrap">
      <a-form-item label="角色: ">
        <a-input v-model.trim="form.roleName" style="width: 150px" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="账户: ">
        <a-input v-model.trim="form.userNo" style="width: 150px" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="模块: ">
        <a-input v-model.trim="form.module" style="width: 150px" placeholder="请输入" />
      </a-form-item>
      <a-form-item label="操作: ">
        <a-select
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode || document.body;
            }
          "
          style="width: 150px"
          v-model.trim="form.operate"
          placeholder="请选择"
        >
          <a-select-option v-for="(item, index) in type" :key="index" :value="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="日期时间范围: " style="poeition: relative">
        <a-range-picker
          v-model="time"
          :getCalendarContainer="getCalendarContainer()"
          :ranges="{ 今天: [moment(), moment()], 本月: [moment(), moment().endOf('month')] }"
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          @change="onChange"
        />
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
      </span>
    </a-form>
    <div class="middle">
      <a-table
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        style="margin-top: 15px"
        :pagination="false"
        :columns="columns"
        :data-source="operationData"
        bordered
      >
        <template slot="roleName" slot-scope="text, record">
          <a-tooltip placement="top">
            <template slot="title">
              <span>{{ record.roleName }}</span>
            </template>

            <div
              style="
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
              "
            >
              {{ record.roleName }}
            </div>
          </a-tooltip>
        </template>
        <template slot="action" slot-scope="text, record">
          <a href="javascript:;" style="color: #1890ff" @click="modify(record)">预览</a>
        </template>
      </a-table>
    </div>
    <div class="fotter">
      <a-pagination
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="['20', '50', '100']"
        :defaultCurrent="pageNum"
        :defaultPageSize="pageSize"
        :total="total"
        :current="pageNum"
        :pageSize="pageSize"
        :show-total="(total) => `共 ${total} 条`"
        :showSizeChanger="true"
        @change="onChangePage"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      pageSize: 20,
      pageNum: 1,
      form: {},
      time: [],
      formIsactive: {}, //=>防止输入空有值未检索 分页会带检索条件
      type: [
        {
          label: "查询",
        },
        {
          label: "新增",
        },
        {
          label: "编辑",
        },
        {
          label: "删除",
        },
      ],
      columns: [
        {
          title: "ID",
          dataIndex: "logNumber",
          defaultSortOrder: "descend",
          sorter: (a, b) => a.logNumber - b.logNumber,
          width: 100,
        },
        {
          title: "角色",
          dataIndex: "roleName",
          scopedSlots: { customRender: "roleName" },
          width: 200,
        },
        {
          title: "账户",
          dataIndex: "userNo",
          width: 200,
        },
        {
          title: "模块",
          dataIndex: "module",
          width: 200,
        },
        {
          title: "操作类型",
          dataIndex: "operate",
          width: 100,
        },

        {
          title: "描述",
          dataIndex: "introduce",
          width: 200,
        },
        {
          title: "对象",
          dataIndex: "reportName",
          width: 200,
        },
        {
          title: "时间",
          dataIndex: "gmtModified",
          defaultSortOrder: "descend",
          sorter: (a, b) => {
            let aTime = new Date(a.gmtModified).getTime();
            let bTime = new Date(b.gmtModified).getTime();
            return aTime - bTime;
          },
          width: 200,
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ],
    };
  },
  created() {
    this.journalDate({ ...this.form, pageNum: this.pageNum, pageSize: this.pageSize });
  },
  computed: {
    ...mapState({
      operationData: (state) => state.journal.Log,
      total: (state) => state.journal.total,
    }),
  },
  mounted() {},
  methods: {
    moment,
    ...mapActions({
      journalDate: "journal/journalDate",
    }),
    getCalendarContainer() {
      return (triggerNode) => triggerNode.parentNode;
    },
    onChange(dates, dateStrings) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      this.time = dateStrings;
    },
    //预览
    modify(record) {
      console.log(record);
      this.$router.push({ path: "/basicInformation/viewReport", query: { record: JSON.stringify(record) } });
    },
    //查询
    search() {
      console.log(this.form);
      this.form.startDate = this.time[0];
      this.form.endDate = this.time[1];
      this.formIsactive = this.form;
      this.pageNum = 1;
      this.journalDate({ ...this.form, pageNum: this.pageNum, pageSize: this.pageSize });
    },
    //重置
    resetSearchForm() {
      this.form = {};
      this.time = [];
      console.log(this.form);
      this.pageNum = 1;
      this.pageSize = 20;
      this.journalDate({ pageNum: 1, pageSize: 20 });
    },
    //分页
    onShowSizeChange(pageNum, pageSize) {
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      this.journalDate({ ...this.formIsactive, pageNum, pageSize });
    },
    onChangePage(pageNum, pageSize) {
      this.pageNum = pageNum;
      this.pageSize = pageSize;
      this.journalDate({ ...this.formIsactive, pageNum, pageSize });
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;
}

.header button {
  margin-left: 20px;
}

.middle {
  margin-top: 15px;
}

.fotter {
  float: right;
  padding: 20px 0;
}

::v-deep .ant-modal-header {
  text-align: center;
  font-weight: bold;
}
::v-deep .ant-form-item {
  display: flex;
}

.btnClass {
  margin-left: 10px;
}
</style>
